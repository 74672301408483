<div class="page-content-wrapper">

  <div id="snackbarPersonDetails">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>

  <div id="snackbarReminderSent">
    <p translate="generic.REMINDER_SENT"></p>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container *ngIf="!isLoadingYearList && !isLoadingPersonDetails && personDetails">
    <header-dossier (onMenuButtonClicked)="changeButtonHeaderDossier($event)" [user]="personDetails"
      [yearItems]="yearList" [currentPercentage]="currentUserPercentage" [selectedYear]="selectedYear"
      (onSelectedYear)="changeSelectedYear($event)" (onNewInterview)="createPrivateNote()">
    </header-dossier>
    <div class="main-content-wrapper">
      <!-- dati semestre H1 H2-->
      <ng-container *ngIf="!isNoteSection">
        <!-- Titolo "Avanzamento in %"-->
        <div class="progress-title-container">
          <p class="progress-title"
            [innerHTML]="('generic.CARD_PROGRESS' | translate) + '<b>' + currentUserPercentage + '% </b>'"></p>
        </div>
        <!-- Contenitore dati caricati -->
        <ng-container *ngIf="!isLoadingTab">
          <child-content *ngIf="currentStep">
            <stepper id="stepper" [ngClass]="{'central-stepper': isLeadershipTeam}" [statuses]="stepList" [dots]="5"
              [currentStatus]="currentStep" (stepClicked)="onStepClicked($event)"></stepper>

            <!-- 1 bis - Insight feedback -->
            <ng-container *ngIf="currentStep?.id == 'insightFeedback'">
              <!-- Placeholder caricamento-->
              <ng-container *ngIf="isLoadingInsightFeedback">
                <div class="page-header">
                  <div class="loader spinner-medium"></div>
                </div>
              </ng-container>

              <ng-container *ngIf="!isLoadingInsightFeedback">
                <div class="page-header">
                  <div id="insightStepPageHeader">
                    <!-- Se sto inviando la richiesta di feedback -->
                    <div class="title-container">
                      <p class="title">
                        {{ ('stepper.INSIGHT_FEEDBACK' | translate) }}
                      </p>
                    </div>
                    <p class="description" *ngIf="personDetails">
                      {{ ('stepper.REQUEST_FEEDBACK_FOR' | translate) + personDetails.forename+ ' ' +
                      personDetails.surname}}
                    </p>
                  </div>

                  <div class="insight-feedback-container"
                    *ngIf="currentStep.status == 'TO_START' || currentStep.status == 'WAITING_FEEDBACK' || (currentStep.status == 'COMPLETED' && insightFeedbackData?.isSkipped)">
                    <!-- Se sto inviando la richiesta di feedback -->
                    <ng-container
                      *ngIf="currentStep.status == 'TO_START' || (currentStep.status == 'COMPLETED' && insightFeedbackData?.isSkipped)">
                      <div class="insight-title">
                        <div class="title-selector-container">
                          <h3 translate="insight.STEP_TITLE"></h3>
                          <!-- Obbligatorietà step insight feedback -->
                          <div class="selector-switch"
                            *ngIf="insightFeedbackData && !insightFeedbackData.isMandatory && !isItemDisabled()">
                            <label class="switch">
                              <input type="checkbox" [checked]="!insightFeedbackData.isSkipped">
                              <span class="slider round" (click)="mandatoryInsightFeedbackChanged()"></span>
                            </label>
                            <p translate="insight.SWITCH_REQUEST"></p>
                          </div>
                        </div>
                        <h5 *ngIf="!insightFeedbackData?.isSkipped" [innerHTML]="'insight.STEP_DESCR' | translate"></h5>
                        <h5 *ngIf="insightFeedbackData?.isSkipped"
                          [innerHTML]="'insight.STEP_DESCR_SKIPPED' | translate"></h5>
                      </div>

                      <ng-container *ngIf="!insightFeedbackData?.isSkipped">
                        <div class="divisor-line"></div>
                        <!-- Se matrix manager preselezionato -->
                        <ng-container
                          *ngIf="preselectedInsightFeedbackManager && selectedManagerForInsightFeedback.title">

                          <div class="insight-feedback-manager-container">
                            <avatar-img [user]="selectedManagerForInsightFeedback" size="medium"></avatar-img>
                            <div class="name-position-container">
                              <h4>{{ selectedManagerForInsightFeedback.title }}</h4>
                              <h5>{{ selectedManagerForInsightFeedback.ramo }} </h5>
                            </div>
                          </div>

                          <div class="divisor-line"></div>
                        </ng-container>

                        <!-- Chi deve esprimere il feedback? -->
                        <ng-container *ngIf="!preselectedInsightFeedbackManager">
                          <input-container [numberCharsTextArea]="300" [isFromTextArea]="true"
                            [isWithoutInfoIcon]="true"
                            [isValued]="selectedManagerForInsightFeedback && selectedManagerForInsightFeedback.id"
                            [isObbligatory]="true" [title]="('insight.WHO' | translate)"
                            [disabled]="isLoadingInsightFeedbackUsers || isInsightItemDisabled()"
                            id="insightFeedbackUser">
                            <alloy-select [inMinWidthForIe]="true" [placeholder]="'insight.WHO_PLH' | translate"
                              (onSelectedItem)="selectUserForInsightFeedback($event)"
                              [isLoading]="isLoadingInsightPeopleList"
                              [selectedItem]="selectedManagerForInsightFeedback" [items]="insightFeedbackUserList"
                              [searchable]="true" bindLabel="id" [typeahead]="insightPeopleSrvSubject"
                              [readonly]="isInsightItemDisabled()">
                            </alloy-select>
                          </input-container>
                        </ng-container>

                        <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                          [isValued]="insightFeedbackMotivation && insightFeedbackMotivation.length && insightFeedbackMotivation.trim().length"
                          [isObbligatory]="true" [title]="('insight.WHY' | translate)"
                          [disabled]="isInsightItemDisabled()" id="competenceEvaluationNotes">
                          <alloy-text-area [bindValue]="insightFeedbackMotivation" [maxlength]="300"
                            (onModelChanged)="onInsightFeedbackCommentsChanged($event)"
                            placeholder="{{'insight.WHY_PLH' | translate}}" [readonly]="isInsightItemDisabled()">
                          </alloy-text-area>
                        </input-container>

                        <div class="action-bar">
                          <alloy-button type="success" size="large" [disabled]="isRequestInsightFeedbackDisabled()"
                            [label]="'insight.REQUEST_FEEDBACK' | translate"
                            (onButtonClicked)="openRequestInsightFeedback()">
                          </alloy-button>
                        </div>
                      </ng-container>
                    </ng-container>
                    <!-- Se sto aspettando la risposta al feedback -->
                    <ng-container *ngIf="currentStep.status == 'WAITING_FEEDBACK'">
                      <div class="insight-title">
                        <h3>{{ ('insight.FEEDBACK_REQUESTED_TO' | translate) +
                          insightFeedbackData?.matrixManager?.forename + ' ' +
                          insightFeedbackData?.matrixManager?.surname}}</h3>
                        <h5 [innerHTML]="'insight.FEEDBACK_REQUESTED_DESCR' | translate"></h5>
                      </div>

                      <div class="divisor-line"></div>

                      <div class="awaiting-feedback-container">
                        <svg-icon src="assets/img/placeholder/insight-feedback-placeholder.svg" class="placeholder-svg">
                        </svg-icon>

                        <p [innerHTML]="'insight.FEEDBACK_REQUESTED_WAITING' | translate"></p>


                        <alloy-button [label]="'insight.SEND_REMINDER' | translate" type="primary blu-link"
                          size="medium" (onButtonClicked)="sendInsightFeedbackReminder()"
                          [disabled]="isLoadingInsightFeedback || isItemDisabled()">
                        </alloy-button>

                      </div>
                    </ng-container>
                  </div>

                  <ng-container *ngIf="currentStep.status == 'COMPLETED' && !insightFeedbackData?.isSkipped">
                    <div class="insight-feedback-card">
                      <!-- lato sinistro avatar utente-->
                      <div class="left">
                        <div class="bubble-container">
                          <avatar-img [user]="insightFeedbackData?.matrixManager" size="medium"></avatar-img>
                          <svg-icon src="/assets/img/icons/baloon-vector.svg">
                          </svg-icon>
                        </div>
                      </div>
                      <div class="center">
                        <!-- Header -->
                        <div class="header">
                          <div class="from">
                            <svg-icon class="small-icon" src="/assets/img/icons/message-square.svg" [applyClass]="true">
                            </svg-icon>
                            <p>{{ 'insight.matrix.FEEDBACK_BY' | translate}}
                              <b>{{insightFeedbackData?.matrixManager?.surname + ' ' +
                                insightFeedbackData?.matrixManager?.forename}}</b>
                            </p>
                          </div>
                          <div class="time">
                            <p>{{insightFeedbackData?.answerDate ? (insightFeedbackData?.answerDate | date:
                              'dd.MM.yyyy')
                              : '--'}}</p>
                            <svg-icon class="small-icon" src=" /assets/img/icons/clock.svg" [applyClass]="true">
                            </svg-icon>
                          </div>
                        </div>
                        <div class="divisor-line"></div>
                        <!-- Commenti ad aree di sviluppo e punti di forza -->
                        <div class="insight-content">
                          <!-- Chi ha richiesto il feedback -->
                          <div class="info-row">
                            <p class="title" [translate]="'insight.matrix.REQUESTED_BY' | translate"></p>
                            <p class="comment">
                              {{insightFeedbackData?.requiredByManager?.surname + ' ' +
                              insightFeedbackData?.requiredByManager?.forename
                              }}
                            </p>
                          </div>
                          <!-- Aree di sviluppo -->
                          <div class="info-row">
                            <p class="title">{{ ('insight.matrix.DEVELOPMENT_AREAS' | translate) }}</p>
                            <p class="comment">{{ insightFeedbackData?.developmentAreasComment }}</p>
                          </div>
                          <!-- Punti di forza -->
                          <div class="info-row">
                            <p class="title">{{ ('insight.matrix.STRENGTH_POINTS' | translate) }}</p>
                            <p class="comment">{{ insightFeedbackData?.pointsOfStrengthComment }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="right"></div>
                    </div>
                    <!-- Commento integrativo -->
                    <div class="insight-feedback-card margin-top32">
                      <div class="left"></div>
                      <div class="center">
                        <div class="additional-comment-header">
                          <!-- header -->
                          <div class="insight-title-container">
                            <div class="title">
                              <h3 [translate]="'insight.ADDITIONAL_COMMENT' | translate"></h3>
                              <svg-icon src="/assets/img/icons/info.svg" class="info-icon medium-size cursor-pointer"
                                [applyClass]="true" (click)="openAdditionalCommentHelper()">
                              </svg-icon>
                            </div>
                            <h5 class="subtitle" [translate]="'insight.ADDITIONAL_COMMENT_SUB' | translate"></h5>
                          </div>

                          <input-container [numberCharsTextArea]="300" [isFromTextArea]="true"
                            [isWithoutInfoIcon]="true"
                            [isValued]="insightFeedbackData?.directManagerComment && insightFeedbackData?.directManagerComment?.length && insightFeedbackData?.directManagerComment?.trim()?.length"
                            [isObbligatory]="false" [title]="('insight.INSERT_COMMENT' | translate)"
                            [disabled]="isItemDisabled()" id="additional-comment-area">
                            <alloy-text-area [bindValue]="insightFeedbackData?.directManagerComment" [maxlength]="300"
                              (onModelChanged)="onInsightFeedbackAdditionalManagerCommentChanged($event)"
                              placeholder="{{'insight.INSERT_COMMENT_PLH' | translate}}" [readonly]="isItemDisabled()">
                            </alloy-text-area>
                          </input-container>

                          <!-- Salva commento manager diretto -->
                          <div class="action-bar">
                            <alloy-button type="success" size="large" [disabled]="isSaveAdditionalCommentDisabled()"
                              [label]="'insight.SAVE_COMMENT' | translate"
                              (onButtonClicked)="commentInsightFeedbackForManager()">
                            </alloy-button>
                          </div>

                        </div>
                      </div>
                      <div class="right"></div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>

            <!-- 1 - Valutazione obiettivi di sviluppo -->
            <ng-container *ngIf="currentStep?.id == 'objectiveEvaluation'">
              <!-- Placeholder caricamento-->
              <ng-container *ngIf="isLoadingToEvaluateGoals">
                <div class="page-header">
                  <div class="loader spinner-medium"></div>
                </div>
              </ng-container>

              <ng-container *ngIf="!isLoadingToEvaluateGoals">
                <!-- Se non ho obiettivi mostro placeholder -->
                <ng-container *ngIf="!toEvaluateList || !toEvaluateList.length">
                  <no-data [title]="('generic.NO_OBJECTIVE_TO_EVALUATE' | translate)"
                    [description]="('generic.NO_OBJECTIVE_TO_EVALUATE_DESCR' | translate)" [hideIcon]="true">
                  </no-data>
                </ng-container>
                <!-- se ho obiettivi -->
                <ng-container *ngIf="toEvaluateList && toEvaluateList.length">
                  <div class="page-header">
                    <div id="firstStepPageHeader">
                      <div class="title-container">
                        <p *ngIf="isIT" class="title">
                          {{ ('stepper.OBJECTIVE_EVALUATION_TITLE_SPECIALIST' | translate) }}
                        </p>
                        <p *ngIf="!isIT" class="title"> {{ ('stepper.OBJECTIVE_EVALUATION_TITLE' | translate) + ' ' +
                          selectedYear.code + ' ' + selectedYear.id}} </p>
                        <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                          (click)="openHelpModal(currentStep.id)">
                        </svg-icon>
                        <svg-icon *ngIf="!isLoadingToEvaluateGoals" src="/assets/img/icons/flag.svg" class="title-icon"
                          [applyClass]="true" (click)="openPersonalObjectiveEvaluationTutorial()">
                        </svg-icon>
                      </div>
                      <p *ngIf="!isIT" class="description"
                        [innerHTML]="'stepper.OBJECTIVE_EVALUATION_DESCR' | translate"></p>
                      <p *ngIf="isIT" class="description"
                        [innerHTML]="'stepper.OBJECTIVE_EVALUATION_DESCR_TECH' | translate"></p>
                    </div>
                  </div>

                  <!-- Componente nuovo obiettivi composti di solo titolo, con valutazione -->
                  <ng-container *ngFor="let objective of toEvaluateList; let toEvaluateItemIndex = index;">
                    <ng-container *ngIf="!isIT">
                      <text-goal
                        [subtitleFooter]="objective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + objective.creationUser?.forename + ' ' + objective.creationUser?.surname) : ''"
                        [readonly]="isItemDisabled()" [title]="objective.text" [evaluation]="objective.evaluation"
                        [isEvaluation]="true" (evaluationChanged)="onEvaluationChanged($event, objective)"
                        [showMenu]="false" [disabled]="isItemDisabled()"
                        [id]="'toEvaluateItemIndex' + toEvaluateItemIndex">
                      </text-goal>
                    </ng-container>

                    <ng-container *ngIf="isIT">
                      <text-goal
                        [subtitleFooter]="objective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + objective.creationUser?.forename + ' ' + objective.creationUser?.surname) : ''"
                        [readonly]="isItemDisabled()" [title]="objective.text" [evaluation]="objective.evaluation"
                        [isEvaluation]="true" (evaluationChanged)="onEvaluationChanged($event, objective)"
                        [showMenu]="false" [disabled]="isItemDisabled()"
                        [id]="'toEvaluateItemIndex' + toEvaluateItemIndex" [ITRating]="isIT"
                        [ITRatingVote]="objective.evaluationVote"
                        (ITRatingVoteChanged)="onITRatingVoteChanged($event, objective)">
                      </text-goal>
                    </ng-container>

                  </ng-container>

                </ng-container>
              </ng-container>

              <div class="person-details-main-actions-banner" id="saveGoalToEvaluateBtn">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button [disabled]="true" type="black-border" size="medium"
                      label="{{'generic.BACK' | translate}}" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs
                      fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button [disabled]="true" type="black-border with-just-left-icon" size="medium" label=""
                      iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <ng-container>
                      <!-- Salva e continua (se ci sono obiettivi da valutare) -->
                      <alloy-button *ngIf="isOptionAvailable() && (toEvaluateList && toEvaluateList.length)"
                        [disabled]="isLoadingToEvaluateGoals" type="primary" size="medium"
                        label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                        (onButtonClicked)="saveObjectiveEvaluation()">
                      </alloy-button>
                      <!-- Va avanti di step (se non ci sono obiettivi da valutare) -->
                      <alloy-button *ngIf="isOptionAvailable() && (!toEvaluateList || !toEvaluateList.length)"
                        [disabled]="isLoadingToEvaluateGoals" type="primary" size="medium"
                        label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="goToNextStep()">
                      </alloy-button>
                      <!-- Va avanti di step (tutto disabilitato) -->
                      <alloy-button *ngIf="isItemDisabled()" [disabled]="isLoadingToEvaluateGoals" type="primary"
                        size="medium" label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="goToNextStep()">
                      </alloy-button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- 2 - Valutazione dei comportamenti organizzativi + Leadership team -->
            <ng-container *ngIf="currentStep?.id == 'behaviourEvaluation'">
              <div class="page-header" id="secondStepPageHeader">
                <div class="title-container">
                  <p class="title" translate="stepper.BEHAVIOUR_EVALUATION"></p>
                  <svg-icon *ngIf="!isLoadingBehaviours && !isLeadershipTeam" src="/assets/img/icons/flag.svg"
                    class="title-icon" [applyClass]="true" (click)="openOrganizationalBehaviorsTutorial()">
                  </svg-icon>
                </div>
                <p *ngIf="!isLeadershipTeam" class="description"
                  [innerHTML]="'stepper.BEHAVIOUR_EVALUATION_DESCR' | translate"></p>
                <p *ngIf="isLeadershipTeam" class="description" [innerHTML]="getLeadershipSubtitleDescr()"></p>
              </div>

              <!-- Comportamenti organizzativi per tutti gli utenti -->
              <ng-container *ngIf="!isLoadingBehaviours">
                <!-- Lista comportamenti leadership -->
                <ng-container *ngIf="isLeadershipTeam">
                  <ng-container *ngFor="let competence of competenceArray; let competenceIndex = index;">
                    <competence-evaluation [competenceList]="competence" [id]="'competenceIndex' + competenceIndex"
                      [isLeadershipRating]="true" [disabled]="isItemDisabled()"
                      (onPlusMinusClicked)="leadershipBehaviorChanged($event)"
                      [isProcessBeforeH22023]="processIsBeforeH2023Leadership">
                    </competence-evaluation>
                  </ng-container>
                </ng-container>

                <!-- Lista comportamenti organizzativi -->
                <ng-container *ngIf="!isLeadershipTeam">
                  <ng-container *ngFor="let competence of competenceArray; let competenceIndex = index;">
                    <competence-evaluation [titlesRightSection]="titlesRightSection" [competenceList]="competence"
                      [id]="'competenceIndex' + competenceIndex" (infoClicked)="openCompetenceDictionaryModal($event)"
                      (ratingChanged)="behaviourRatingChanged($event)" [ratingScale]="competence.ratingScale"
                      [disabled]="isItemDisabled()">
                    </competence-evaluation>
                  </ng-container>
                </ng-container>

                <!-- Media finale -->
                <competence-average
                  *ngIf="!isLeadershipTeam && softSkillsFinalAvg && softSkillsFinalAvg.allBehavioursValuated"
                  [disabled]="true" id="soft-skill-final-avg" [competenceAvg]="softSkillsFinalAvg"
                  [titlesRightSection]="titlesRightSection" [ratingScale]="softSkillsFinalAvg.ratingScale">
                </competence-average>

                <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                  [isValued]="manageBehaviorComments && manageBehaviorComments.trim().length" [isObbligatory]="false"
                  title="{{'generic.NOTES_COMMENTS' | translate}}" [disabled]="isItemDisabled()"
                  id="competenceEvaluationNotes">
                  <alloy-text-area [bindValue]="manageBehaviorComments" [maxlength]="300"
                    (onModelChanged)="onManageBehaviorCommentChanged($event)"
                    placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}" [readonly]="isItemDisabled()">
                  </alloy-text-area>
                </input-container>

              </ng-container>

              <ng-container *ngIf="isLoadingBehaviours">
                <div class="loader spinner-medium"></div>
              </ng-container>

              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <ng-container *ngIf="!isLeadershipTeam">
                      <alloy-button *ngIf="isOptionAvailable()" [disabled]="isLoadingBehaviours" type="primary"
                        size="medium" label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                        (onButtonClicked)="saveBehaviourEvaluation()">
                      </alloy-button>
                    </ng-container>
                    <ng-container *ngIf="isLeadershipTeam">
                      <alloy-button *ngIf="isOptionAvailable()" [disabled]="isInCompletedBehaviours" type="primary"
                        size="medium" label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                        (onButtonClicked)="saveBehaviourEvaluation()">
                      </alloy-button>
                    </ng-container>
                    <alloy-button *ngIf="isItemDisabled()" [disabled]="isLoadingBehaviours" type="primary" size="medium"
                      label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="saveBehaviourEvaluation()">
                    </alloy-button>
                  </div>
                </div>
              </div>

            </ng-container>

            <!-- 3 - Valutazione competenze tecniche -->
            <ng-container *ngIf="currentStep?.id == 'techEvaluation'">
              <div class="page-header" id="thirdStepPageHeader">
                <div class="title-container">
                  <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="title"
                    translate="stepper.TECH_SKILL_EVALUATION"></p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="title" translate="stepper.TECH_OBJECTIVES"></p>

                  <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                    (click)="openHelpModal(currentStep.id)">
                  </svg-icon>
                  <svg-icon *ngIf="!isLoadingTechSkills" src="/assets/img/icons/flag.svg" class="title-icon"
                    [applyClass]="true" (click)="openTechSkillEvaluationTutorial()">
                  </svg-icon>
                </div>
                <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="description"
                  [innerHTML]="'stepper.TECH_SKILL_EVALUATION_DESCR' | translate"></p>
                <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="description"
                  [innerHTML]="'stepper.TECH_OBJECTIVES_DESCR' | translate"></p>
              </div>

              <!-- techSkill -->
              <ng-container *ngIf="!isLoadingTechSkills">

                <ng-container *ngFor="let techSkill of techSkillArray; let techSkillIndex = index;">
                  <competence-evaluation [titlesRightSection]="titlesRightSection" [competenceList]="techSkill"
                    [id]="'competenceIndex' + techSkillIndex" (infoClicked)="openCompetenceDictionaryModal($event)"
                    (ratingChanged)="onTechSkillRatingChanged($event)" [ratingScale]="techSkill.ratingScale"
                    [disabled]="isScheduleShared || isFinalStepCompleted">
                  </competence-evaluation>
                </ng-container>

                <!-- Media finale -->
                <competence-average *ngIf="techSkillsFinalAvg && techSkillsFinalAvg.allBehavioursValuated"
                  [disabled]="true" id="tech-skill-final-avg" [competenceAvg]="techSkillsFinalAvg"
                  [ratingScale]="techSkillsFinalAvg.ratingScale" [titlesRightSection]="titlesRightSection">
                </competence-average>

                <input-container id="techEvaluationNotes" [numberCharsTextArea]="300" [isFromTextArea]="true"
                  [isWithoutInfoIcon]="true" [isValued]="techSkillComment && techSkillComment.trim().length"
                  [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}"
                  [disabled]="isScheduleShared || isFinalStepCompleted">
                  <alloy-text-area [bindValue]="techSkillComment" [maxlength]="300"
                    (onModelChanged)="onTechSkillCommentChanged($event)"
                    placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}"
                    [readonly]="isScheduleShared || isFinalStepCompleted">
                  </alloy-text-area>
                </input-container>
              </ng-container>
              <!-- loader competenze tecniche -->
              <ng-container *ngIf="isLoadingTechSkills">
                <div class="loader spinner-medium"></div>
              </ng-container>
              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <alloy-button *ngIf="isOptionAvailable()" [disabled]="isLoadingTechSkills" type="primary"
                      size="medium" label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                      (onButtonClicked)="saveTechSkills()">
                    </alloy-button>
                    <!-- Va avanti di step (tutto disabilitato) -->
                    <alloy-button *ngIf="isItemDisabled()" [disabled]="isLoadingToEvaluateGoals" type="primary"
                      size="medium" label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="goToNextStep()">
                    </alloy-button>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- 4 - Definisci obiettivi -->
            <ng-container *ngIf="currentStep?.id == 'defineObjectives'">
              <div class="page-header white-bg">
                <div class="title-container" id="fourthStepPageHeader">
                  <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate && !isIT" class="title"
                    translate="stepper.DEFINE_NEW_OBJECTIVES"></p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate && selectedYear.id == 2023 || isIT" class="title"
                    translate="stepper.PROPOSE_SPECIALIST_NEW_OBJECTIVES"></p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate && selectedYear.id < 2023" class="title"
                    translate="stepper.DEFINE_NEW_OBJECTIVES"></p>
                  <svg-icon *ngIf="!isLoadingDefinedObjectives && !isLoadingUserGoalToDefine"
                    src="/assets/img/icons/flag.svg" class="title-icon" [applyClass]="true"
                    (click)="openSetGoalsTutorial()">
                  </svg-icon>
                </div>
                <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate  && !isIT" class="description"
                  [innerHTML]="'stepper.DEFINE_OBJECTIVES_DESCR' | translate"></p>
                <p *ngIf="yearsWithSpecialisticGoalsToEvaluate || isIT" class="description"
                  [innerHTML]="'stepper.PROPOSE_SPECIALIST_NEW_OBJECTIVES_DESCR_MANAGER' | translate"></p>
                <!-- Lista obiettivi -->
                <ng-container *ngIf="!isLoadingDefinedObjectives">
                  <ng-container *ngFor="let objective of newObjectiveList; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal
                        [subtitle]="objective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + objective.creationUser?.forename + ' ' + objective.creationUser?.surname) : ''"
                        [title]="objective.title" [showMenu]="isOptionAvailable()" [canEdit]="isOptionAvailable()"
                        (menuOptionClicked)="onMenuOptionClicked($event, objective)">
                      </text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isCreateObjective]="true" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{ (yearsWithSpecialisticGoalsToEvaluate || isIT ?('defineObjectives.DEVELOPMENT_SPECIALIST_OBJECTIVE' | translate) : ('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate)) + ' ' + (i + 1)}}"
                        (deleteObjective)="onMenuOptionClicked('delete', objective)" [disabled]="isScheduleShared">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'defineObjectives.INSERT_OBJECTIVE' | translate}}"
                          [readonly]="isScheduleShared">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <!-- Aggiungi obiettivo -->
                  <ng-container *ngIf="isOptionAvailable()">
                    <alloy-button id="addGoalBtn" type="add-objective link" size="large"
                      label="{{'defineObjectives.ADD_OBJECTIVE' | translate}}" iconRight="assets/img/icons/plus.svg"
                      (onButtonClicked)="addNewObjective()"></alloy-button>
                  </ng-container>

                </ng-container>

                <!-- Loader -->
                <ng-container *ngIf="isLoadingDefinedObjectives">
                  <div class="loader spinner-small"></div>
                </ng-container>
              </div>

              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <alloy-button *ngIf="isOptionAvailable()" id="saveGoalBtn"
                      [disabled]="isLoadingDefinedObjectives || !canSaveDefinedObjectives()" type="primary"
                      size="medium" label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                      (onButtonClicked)="saveDefinedObjective()">
                    </alloy-button>
                    <!-- Va avanti di step (tutto disabilitato) -->
                    <alloy-button *ngIf="isItemDisabled()" [disabled]="isLoadingToEvaluateGoals" type="primary"
                      size="medium" label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="goToNextStep()">
                    </alloy-button>
                  </div>
                </div>
              </div>
            </ng-container>


            <!-- 5 - Valutazione finale -->
            <ng-container *ngIf="currentStep?.id == 'finalEvaluation'">
              <!-- Riepilogo valutazione finale -->
              <ng-container *ngIf="!isLoadingFinalEvaluationData && !isLeadershipTeam">

                <ng-container *ngIf="checkNewObjectives()">
                  <div class="notice-new-objective-container">
                    <div class="notice-container">
                      <div class="dot"></div>
                      <p>{{ newObjectiveMessage }}</p>
                    </div>
                    <p class="go-to-link" translate="finalEvaluation.NOTICE_ACTION" (click)="scrollToNewObjective()">
                    </p>
                  </div>
                </ng-container>

                <!-- con la valutazione e con la modale con le informazioni -->
                <ng-container *ngIf="finalEvaluation.pasPerformer && finalEvaluation.pasPerformer.value">
                  <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="space-between start"
                    fxLayoutAlign="start center" class="grafics-final-evaluation-container">
                    <div class="performer-avaluator-container"
                      [ngClass.gt-sm]="{'border-right': finalEvaluation.behaviorCurrentStars}" fxLayout="column">
                      <p class="text-title" translate="stepper.RIEPILOGUE"></p>
                      <performer-evaluator [performerValue]="finalEvaluation.pasPerformer.value"
                        [enablePerformerInfo]="true" (openPerformerModal)="openPerformerModal($event)"
                        [sentToHr]="isScheduleShared" [hrRemarks]="finalEvaluation.adminComment"
                        [enableHrRemarks]="isPostCalibration" [isHrRemarksVisible]="true"
                        [hideTitle]="finalEvaluation.pasPerformer.value == 0">
                      </performer-evaluator>
                    </div>
                    <div *ngIf="finalEvaluation.behaviorCurrentStars" class="progress-grafic-container full-width">
                      <p class="text-title margin-bottom40" translate="stepper.PROGRESS_GRAFIC"></p>

                      <div *ngIf="isIT" fxLayout="row" class="full-width margin-bottom24" fxLayoutAlign="start center">
                        <p class="text-desc margin-right16" translate="generic.TECHNIC_OBJECTIVES"></p>
                        <div class="width-115 margin-right8" fxLayout="column">
                          <div class="first-star-row"
                            [ngStyle]="{'width': (115*finalEvaluation.goalCurrentAvg/5) + 'px'}"></div>
                          <div class="second-star-row"
                            [ngStyle]="{'width': (115*finalEvaluation.goalPastAvg/5) + 'px'}"></div>
                        </div>
                        <div fxLayout="column">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <p class="star-value-text bold-text">{{finalEvaluation.goalCurrentAvgLabel}}</p>
                            <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                              [forceRating]="finalEvaluation.goalCurrentStars" [hideLabel]="true"
                              [isRecapAverage]="true"></star-rating>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <p class="star-value-text bold-text">{{finalEvaluation.goalPastAvgLabel}}</p>
                            <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                              [forceRating]="finalEvaluation.goalPastStars" [hideLabel]="true" [isRecapAverage]="true">
                            </star-rating>
                          </div>
                        </div>
                      </div>

                      <div fxLayout="row" class="full-width margin-bottom24" fxLayoutAlign="start center">
                        <p class="text-desc margin-right16" translate="generic.ORGANIZED_BEHAVIORS"></p>
                        <div class="width-115 margin-right8" fxLayout="column">
                          <div class="first-star-row"
                            [ngStyle]="{'width': (115*finalEvaluation.behaviorCurrentAvg/5) + 'px'}"></div>
                          <div class="second-star-row"
                            [ngStyle]="{'width': (115*finalEvaluation.behaviorPastAvg/5) + 'px'}"></div>
                        </div>
                        <div fxLayout="column">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <p class="star-value-text bold-text">{{finalEvaluation.behaviorCurrentAvg}}</p>
                            <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                              [forceRating]="finalEvaluation.behaviorCurrentStars" [hideLabel]="true"
                              [isRecapAverage]="true"></star-rating>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <p class="star-value-text bold-text">{{finalEvaluation.behaviorPastAvg}}</p>
                            <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                              [forceRating]="finalEvaluation.behaviorPastStars" [hideLabel]="true"
                              [isRecapAverage]="true"></star-rating>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="!isIT" fxLayout="row" class="full-width margin-bottom24" fxLayoutAlign="start center">
                        <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="text-desc margin-right16"
                          translate="dashboard.COMPETENCES"></p>
                        <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="text-desc margin-right16"
                          translate="dashboard.SPECIALIST_COMPETENCES"></p>
                        <div class="width-115 margin-right8" fxLayout="column">
                          <div class="first-star-row"
                            [ngStyle]="{'width': (115*finalEvaluation.competenceCurrentAvg/5) + 'px'}"></div>
                          <div class="second-star-row"
                            [ngStyle]="{'width': (115*finalEvaluation.competencePastAvg/5) + 'px'}"></div>
                        </div>
                        <div fxLayout="column">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <p class="star-value-text bold-text">{{finalEvaluation.competenceCurrentAvg}}</p>
                            <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                              [forceRating]="finalEvaluation.competenceCurrentStars" [hideLabel]="true"
                              [isRecapAverage]="true"></star-rating>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <p class="star-value-text bold-text">{{finalEvaluation.competencePastAvg}}</p>
                            <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                              [forceRating]="finalEvaluation.competencePastStars" [hideLabel]="true"
                              [isRecapAverage]="true"></star-rating>
                          </div>
                        </div>
                      </div>

                      <div fxLayout="row" class="full-width margin-bottom24 overflow-hidden"
                        fxLayoutAlign="start center">
                        <p class="text-desc margin-right16" translate="stepper.FINAL_EVAL_PAS"></p>
                        <div class="width-115 margin-right8" fxLayout="column">
                          <div class="first-star-row"
                            [ngStyle]="{'width': (115*finalEvaluation.pasCurrentAvg/5) + 'px'}"></div>
                          <div class="second-star-row" [ngStyle]="{'width': (115*finalEvaluation.pasPastAvg/5) + 'px'}">
                          </div>
                        </div>
                        <div fxLayout="column">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <p class="star-value-text bold-text">{{finalEvaluation.pasCurrentAvg}}</p>
                            <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                              [forceRating]="finalEvaluation.pasCurrentStars" [hideLabel]="true"></star-rating>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <p class="star-value-text bold-text">{{finalEvaluation.pasPastAvg}}</p>
                            <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                              [forceRating]="finalEvaluation.pasPastStars" [hideLabel]="true">
                            </star-rating>
                          </div>
                        </div>
                      </div>

                      <div fxLayout="row" class="full-width margin-bottom24 overflow-hidden"
                        fxLayoutAlign="start center">
                        <p class="text-desc margin-right16" translate="stepper.FINAL_EVA_DELTA"></p>
                        <div class="margin-right8" fxLayout="column">
                          <div fxLayout="row">
                            <p class="star-value-text bold-text">{{finalEvaluation.deltaAvg}}</p>
                            <svg-icon src="assets/img/icons/star.svg" [applyClass]="true" class="star-icon-green">
                            </svg-icon>
                          </div>
                          <!-- <p class="star-value-text" translate="stepper.UP_PAS_VALUE"></p> -->
                        </div>
                      </div>

                      <div fxLayout="row" fxLayoutAlign="center center" class="legend-grafic full-width">
                        <div class="current-circle"></div>
                        <p class="text-desc margin-right50" [ngClass.xs]="'margin-right5'">{{selectedYear?.name}}</p>
                        <div class="past-circle"></div>
                        <p translate="stepper.PRECEDENT_SEMESTER" class="text-desc"></p>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- valutazione ancora da impostare e con la modale con le informazioni -->
                <ng-container *ngIf="!finalEvaluation.pasPerformer || !finalEvaluation.pasPerformer.value">
                  <performer-evaluator [hasToSetVal]="true" [enablePerformerInfo]="true"
                    [hideTitle]="!finalEvaluation.pasPerformer || finalEvaluation.pasPerformer.value == 0"
                    (openPerformerModal)="openPerformerModal($event)" class="white-box-with-shadow">
                  </performer-evaluator>
                </ng-container>
              </ng-container>

              <!-- Recap fasi precedenti -->
              <!-- Placeholder obiettivi valutati -->
              <ng-container *ngIf="isLoadingFinalEvaluationData && !isLeadershipTeam">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title" translate="stepper.OBJECTIVE_EVALUATION_TITLE"></p>
                  </div>
                  <div class="objective-tag-container">
                    <div class="loader spinner-medium"></div>
                  </div>
                </div>
              </ng-container>

              <!-- Recap valutazione obiettivi -->
              <ng-container *ngIf="!isLoadingFinalEvaluationData && !isLeadershipTeam">
                <ng-container *ngIf="finalEvaluation.evaluatedData && finalEvaluation.evaluatedData.length">
                  <!-- Recap valutazione obiettivi di sviluppo -->
                  <div class="page-header white-bg">
                    <div class="title-container">
                      <p *ngIf="isIT" class="title"> {{ ('stepper.OBJECTIVE_EVALUATION_TITLE_SPECIALIST' | translate) }}
                      </p>
                      <p *ngIf="!isIT" class="title">{{ ('stepper.OBJECTIVE_EVALUATION_TITLE' | translate) + ' ' +
                        selectedYear.code
                        + ' ' + selectedYear.id}}</p>
                    </div>

                    <!-- Componente nuovo obiettivi composti di solo titolo, con valutazione -->
                    <div class="objective-tag-container">
                      <!-- Placeholder nessun obiettivo -->
                      <ng-container *ngIf="!selectedObjective || finalEvaluation.emptyEvaluatedObjective">
                        <p class="placeholder margin" translate="defineObjectives.OBJECTIVE_NOT_EVALUATED"></p>
                      </ng-container>

                      <!-- recap obiettivi -->
                      <ng-container *ngIf="selectedObjective && !finalEvaluation.emptyEvaluatedObjective">
                        <ng-container *ngFor="let objective of finalEvaluation.evaluatedData; let i = index">
                          <div class="objective-tag" (click)="selectObjectiveFinalEvaluation(objective)"
                            [ngClass]="{ 'selected': selectedObjective && selectedObjective.goalId && selectedObjective.goalId == objective.goalId }">
                            <p>{{ ('finalEvaluation.OBJECTIVE' | translate) + ' ' + ( i + 1 )}}</p>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>

                    <!-- Recap obiettivo selezionato -->
                    <ng-container *ngIf="selectedObjective && !finalEvaluation.emptyEvaluatedObjective">
                      <div class="objective-evaluation-content">
                        <!-- Obiettivo -->
                        <text-goal
                          [subtitle]="selectedObjective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + selectedObjective.creationUser?.forename + ' ' + selectedObjective.creationUser?.surname) : ''"
                          [title]="selectedObjective.text" [showMenu]="false"></text-goal>
                        <evaluation-rating *ngIf="isIT" [user]="loggedUser"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + loggedUser.forename"
                          [evaluationText]="selectedObjective.evaluation" [ratingIT]="selectedObjective.evaluationVote"
                          [canEdit]="canEditPostCalibration()" [isEditDisabled]="!canEditPostCalibration()"
                          (onEdit)="editCurrentObjective(selectedObjective)">
                        </evaluation-rating>
                        <!-- Valutazione obiettivo people appraisal -->
                        <ng-container *ngIf="loggedUser && !selectedObjective.editObjectiveEvaluation">
                          <ng-container *ngIf="!isIT">
                            <evaluation-rating [user]="loggedUser"
                              [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + insertByManager"
                              [evaluationText]="selectedObjective.evaluation || ('generic.EVALUATION_INCOMPLETE' | translate)"
                              [canEdit]="canEditPostCalibration()" [isEditDisabled]="!canEditPostCalibration()"
                              (onEdit)="editCurrentObjective(selectedObjective)">
                            </evaluation-rating>
                          </ng-container>
                        </ng-container>

                        <ng-container *ngIf="selectedObjective.editObjectiveEvaluation">
                          <input-container [numberCharsTextArea]="300" [isFromTextArea]="true"
                            [isWithoutInfoIcon]="true"
                            [isValued]="selectedObjective.evaluation && selectedObjective.evaluation.trim().length"
                            [isObbligatory]="false" title="{{'textGoal.INSERT_LABEL' | translate}}">
                            <alloy-text-area [bindValue]="selectedObjective.evaluation" [maxlength]="300"
                              (onModelChanged)="onObjectiveEvaluationEdited($event)"
                              placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                            </alloy-text-area>
                          </input-container>
                          <!-- Salva edit obiettivi riepilogo -->
                          <div class="observation-action">
                            <alloy-button [disabled]="!isSelectedObjectiveEvaluationChanged()" type="success"
                              size="medium" [label]="'finalEvaluation.SAVE_COMMENT_EDIT' | translate"
                              (click)="saveObjectiveEvaluation(true, true)">
                            </alloy-button>
                          </div>
                        </ng-container>

                        <!-- Valutazione obiettivo self-assessment-->
                        <ng-container
                          *ngIf="(isPostCalibration || isFinalStepCompleted) && selectedObjective.selfEvaluation">
                          <ng-container *ngIf="!isIT">
                            <evaluation-rating [user]="personDetails"
                              [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + personDetails.forename"
                              [evaluationText]="selectedObjective.selfEvaluation">
                            </evaluation-rating>
                          </ng-container>
                          <ng-container *ngIf="isIT">
                            <evaluation-rating [user]="personDetails"
                              [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + personDetails.forename"
                              [evaluationText]="selectedObjective.selfEvaluation"
                              [ratingIT]="selectedObjective.selfEvaluationVote" [isSelf]="true">
                            </evaluation-rating>
                          </ng-container>
                        </ng-container>

                      </div>
                    </ng-container>

                    <competence-average *ngIf="goalsAverage && isIT" [ratingScale]="goalsAverage.ratingScale"
                      [competenceAvg]="goalsAverage" [titlesRightSection]="titlesRightSection">
                    </competence-average>
                  </div>
                </ng-container>
              </ng-container>

              <!-- Box titolo riepilogo per comportamenti leadership -->
              <ng-container *ngIf="isLeadershipTeam">
                <div class="page-header white-bg">
                  <div class="title-container" [ngClass]="{'margin-bottom0': !isScheduleShared}">
                    <p class="title" translate="stepper.RECAP"></p>
                  </div>
                  <!-- Scheda leadership inviata all'HR -->
                  <div class="sent-to-hr" *ngIf="isScheduleShared">
                    <div class="sent-to-hr-label">
                      <p translate="performerEvaluators.SENT_TO_HR"></p>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- Recap valutazione comportamenti -->
              <div class="page-header white-bg">
                <div class="title-container">
                  <p class="title" translate="stepper.BEHAVIOUR_EVALUATION"></p>
                </div>
                <p class="description" [innerHTML]="'stepper.BEHAVIOUR_EVALUATION_DESCR_FE' | translate"></p>

                <ng-container *ngIf="!isLoadingFinalEvaluationData">
                  <!-- placeholder valutazione incompleta -->
                  <ng-container *ngIf="!finalEvaluation.behaviorData || !finalEvaluation.behaviorData.rating ||
                    !finalEvaluation.behaviorData.ratingValue">
                    <star-rating [disabled]="true" [forceRating]="0"
                      [forceLabel]="'generic.EVALUATION_INCOMPLETE' | translate"></star-rating>
                  </ng-container>

                  <ng-container
                    *ngIf="finalEvaluation.behaviorData && finalEvaluation.behaviorData.rating && finalEvaluation.behaviorData.ratingValue">
                    <ng-container *ngIf="editBehaviorComment">
                      <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                        [isValued]="finalEvaluation.behaviorData.comment && finalEvaluation.behaviorData.comment.trim().length"
                        [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}">
                        <alloy-text-area [bindValue]="finalEvaluation.behaviorData.comment" [maxlength]="300"
                          (onModelChanged)="onBehaviorCommentEdited($event)"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                      </input-container>

                      <div class="observation-action">
                        <alloy-button [disabled]="!isFinalBehaviourDataChanged()" type="success" size="medium"
                          [label]="'finalEvaluation.SAVE_COMMENT_EDIT' | translate" (click)="updateBehaviourComment()">
                        </alloy-button>
                      </div>
                    </ng-container>

                    <!-- Rating comportamenti people appraisal -->
                    <ng-container *ngIf="loggedUser && !editBehaviorComment">
                      <evaluation-rating [user]="loggedUser"
                        [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + insertByManager"
                        [evaluationText]="finalEvaluation.behaviorData.comment"
                        [rating]="finalEvaluation.behaviorData.rating"
                        [ratingValue]="finalEvaluation.behaviorData.ratingValue" [canEdit]="canEditPostCalibration()"
                        [isEditDisabled]="!canEditPostCalibration()" (onEdit)="onEditBehaviourComment()"
                        [isLeadershipTeam]="isLeadershipTeam">
                      </evaluation-rating>
                    </ng-container>

                    <!-- Rating comportamenti self-assessment -->
                    <ng-container
                      *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.behaviorData.selfRating && finalEvaluation.behaviorData.selfRatingValue">
                      <evaluation-rating [user]="personDetails"
                        [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + personDetails.forename"
                        [evaluationText]="finalEvaluation.behaviorData.selfComment"
                        [rating]="finalEvaluation.behaviorData.selfRating"
                        [ratingValue]="finalEvaluation.behaviorData.selfRatingValue" [isSelf]="true" [canEdit]="false"
                        [isLeadershipTeam]="isLeadershipTeam">
                      </evaluation-rating>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="objective-tag-container">
                    <div class="loader spinner-medium"></div>
                  </div>
                </ng-container>
              </div>

              <!-- Note e commenti leadership team -->
              <ng-container *ngIf="isLeadershipTeam">
                <div class="page-header white-bg">
                  <!-- Commento PA e SA readonly -->
                  <ng-container *ngIf="editFinalEvaluationComment">
                    <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                      [isValued]="finalEvaluation.comment && finalEvaluation.comment.trim().length"
                      [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}">
                      <alloy-text-area [bindValue]="finalEvaluation.comment" [maxlength]="300"
                        (onModelChanged)="onFinalCommentEdited($event)"
                        placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                      </alloy-text-area>
                    </input-container>

                    <div *ngIf="isScheduleShared || isPostCalibration" class="observation-action">
                      <alloy-button [disabled]="!isFinalCommentDataChanged()" type="success" size="medium"
                        [label]="'finalEvaluation.SAVE_COMMENT_EDIT' | translate"
                        (click)="updateFinalEvaluationComment()">
                      </alloy-button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!editFinalEvaluationComment">
                    <div class="title-container">
                      <p class="title" translate="finalEvaluation.NOTES_COMMENTS"></p>
                    </div>
                    <ng-container *ngIf="editFinalEvaluationComment">
                      <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                        [isValued]="finalEvaluation.comment && finalEvaluation.comment.trim().length"
                        [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}">
                        <alloy-text-area [bindValue]="finalEvaluation.comment" [maxlength]="300"
                          (onModelChanged)="onFinalCommentEdited($event)"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                      </input-container>

                      <div class="observation-action">
                        <alloy-button [disabled]="!isFinalCommentDataChanged()" type="success" size="medium"
                          [label]="'finalEvaluation.SAVE_COMMENT_EDIT' | translate"
                          (click)="updateFinalEvaluationComment()">
                        </alloy-button>
                      </div>
                    </ng-container>
                    <!-- Commento valutazione finale manager -->
                    <ng-container *ngIf="!editFinalEvaluationComment">
                      <evaluation-rating [user]="loggedUser"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + insertByManager"
                        [evaluationText]="finalEvaluation.comment" [canEdit]="canEditPostCalibration()"
                        [isEditDisabled]="!canEditPostCalibration()" (onEdit)="onEditFinalEvaluationComment()">
                      </evaluation-rating>
                    </ng-container>

                    <!-- Commento valutazione finale self assessment -->
                    <ng-container *ngIf="!editFinalEvaluationComment && (isPostCalibration || isFinalStepCompleted)">
                      <evaluation-rating [user]="personDetails"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + personDetails.forename"
                        [canEdit]="false" [evaluationText]="finalEvaluation.selfComment" [isSelf]="true">
                      </evaluation-rating>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>

              <!-- Recap valutazione competenze tecniche -->
              <ng-container *ngIf="!isManagement && !isLeadershipTeam">
                <!-- Se non è un utente del Management-->
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="title"
                      translate="stepper.TECH_SKILL_EVALUATION"></p>
                    <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="title" translate="stepper.TECH_OBJECTIVES">
                    </p>
                  </div>
                  <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="description"
                    [innerHTML]="'stepper.TECH_SKILL_EVALUATION_DESCR_FE' | translate"></p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="description"
                    [innerHTML]="'stepper.TECH_OBJECTIVES_DESCR_FE' | translate"></p>

                  <ng-container *ngIf="!isLoadingFinalEvaluationData">
                    <!-- placeholder valutazione incompleta -->
                    <ng-container
                      *ngIf="!finalEvaluation.competenceData || !finalEvaluation.competenceData.rating || !finalEvaluation.competenceData.ratingValue">
                      <star-rating [disabled]="true" [forceRating]="0"
                        [forceLabel]="'generic.EVALUATION_INCOMPLETE' | translate"></star-rating>
                    </ng-container>

                    <ng-container
                      *ngIf="finalEvaluation.competenceData && finalEvaluation.competenceData.rating && finalEvaluation.competenceData.ratingValue">

                      <!-- Rating competenze tecniche people appraisal -->
                      <ng-container *ngIf="loggedUser && !editCompetenceComment">
                        <evaluation-rating [user]="loggedUser"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + insertByManager"
                          [evaluationText]="finalEvaluation.competenceData.comment"
                          [rating]="finalEvaluation.competenceData.rating"
                          [ratingValue]="finalEvaluation.competenceData.ratingValue"
                          [canEdit]="canEditPostCalibration()" [isEditDisabled]="!canEditPostCalibration()"
                          (onEdit)="onEditCompetenceComment($event)">
                        </evaluation-rating>
                      </ng-container>

                      <!-- Edit commento competenze -->
                      <ng-container *ngIf="editCompetenceComment">
                        <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                          [isValued]="finalEvaluation.competenceData.comment && finalEvaluation.competenceData.comment.trim().length"
                          [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}">
                          <alloy-text-area [bindValue]="finalEvaluation.competenceData.comment" [maxlength]="300"
                            (onModelChanged)="onCompetenceCommentEdited($event)"
                            placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                          </alloy-text-area>
                        </input-container>

                        <!-- Salva edit commento finale competenze -->
                        <div class="observation-action">
                          <alloy-button [disabled]="!isFinalCompetenceDataChanged()" type="success" size="medium"
                            [label]="'finalEvaluation.SAVE_COMMENT_EDIT' | translate"
                            (click)="updateFinalCompetenceComment()">
                          </alloy-button>
                        </div>
                      </ng-container>

                      <!-- Rating competenze tecniche self-assessment -->
                      <ng-container
                        *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.competenceData.selfRating && finalEvaluation.competenceData.selfRatingValue">
                        <evaluation-rating [user]="personDetails"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + personDetails.forename"
                          [evaluationText]="finalEvaluation.competenceData.selfComment"
                          [ratingValue]="finalEvaluation.competenceData.selfRatingValue" [canEdit]="false"
                          [rating]="finalEvaluation.competenceData.selfRating" [isSelf]="true">
                        </evaluation-rating>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="isLoadingFinalEvaluationData">
                    <div class="objective-tag-container">
                      <div class="loader spinner-medium"></div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>


              <!-- Recap obiettivi definiti -->
              <div class="page-header white-bg" *ngIf="!isLeadershipTeam">
                <div class="title-container">
                  <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate && !isIT" class="title"> {{
                    ('finalEvaluation.OBJECTIVE_DEVELOPMENT' | translate) +
                    ('headerDossier.CARD_MIN' |
                    translate) + getYearName()}}</p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate || isIT" class="title"> {{
                    ('finalEvaluation.TECHNICAL_SPECIALISED_OBJECTIVES' | translate) +
                    ('headerDossier.CARD_MIN' |
                    translate) + getYearName()}}</p>
                </div>
                <p class="description" *ngIf="!yearsWithSpecialisticGoalsToEvaluate && !isIT"
                  [innerHTML]="('finalEvaluation.ASSIGNED_LIST_1' | translate) + personDetails.forename + ('finalEvaluation.ASSIGNED_LIST_2' | translate)">
                </p>
                <p class="description mb-0" *ngIf="yearsWithSpecialisticGoalsToEvaluate || isIT"
                  [innerHTML]="('finalEvaluation.ASSIGNED_SPECIALISED_LIST_1' | translate) + personDetails.forename + ('finalEvaluation.ASSIGNED_SPECIALISED_LIST_2' | translate)">
                </p>
                <p class="subtile-goal-minimum" *ngIf="yearsWithSpecialisticGoalsToEvaluate || isIT"
                  [innerHTML]="('finalEvaluation.MINIMUM_GOAL' | translate)">
                </p>

                <!-- Lista obiettivi per il semestre successivo -->
                <ng-container *ngIf="!isLoadingFinalEvaluationData">

                  <ng-container *ngFor="let objective of finalEvaluation.definedObjectives; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal
                        [subtitle]="objective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + objective.creationUser?.forename + ' ' + objective.creationUser?.surname) : ''"
                        [title]="objective.title" [showMenu]="isOptionAvailable()" [canEdit]="isOptionAvailable()"
                        (menuOptionClicked)="onEditMenuOptionClicked($event, objective)"></text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{ (yearsWithSpecialisticGoalsToEvaluate || isIT ?('defineObjectives.DEVELOPMENT_SPECIALIST_OBJECTIVE' | translate) : ('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate)) + ' ' + (i + 1)}}">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!finalEvaluation.definedObjectives || !finalEvaluation.definedObjectives.length">
                    <p class="placeholder margin" translate="defineObjectives.NO_OBJECTIVE"></p>
                  </ng-container>
                </ng-container>

                <!-- Obiettivi definiti dall'utente -->
                <ng-container
                  *ngIf="isPostCalibration && finalEvaluation.selfDefinedObjectives && finalEvaluation.selfDefinedObjectives.length">

                  <div #newObjectiveContainer class="description-container margin-top40">
                    <p class="description" [innerHTML]="recapSubordinateDevelopmentDescription"></p>
                    <p class="text underline" translate="defineObjectives.NOTICE"></p>
                  </div>

                  <ng-container *ngFor="let objective of finalEvaluation.selfDefinedObjectives; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal [title]="objective.title" [showMenu]="true || isOptionAvailable()"
                        [subtitle]="objective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + objective.creationUser?.forename + ' ' + objective.creationUser?.surname) : ''"
                        [canEdit]="true || isOptionAvailable()" [isToApprove]="objective.goalToBeApproved"
                        (menuOptionClicked)="onEditMenuOptionClicked($event, objective, true)"></text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{ (yearsWithSpecialisticGoalsToEvaluate || isIT ?('defineObjectives.DEVELOPMENT_SPECIALIST_OBJECTIVE' | translate) : ('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate)) + ' ' + (i + 1)}}">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="!finalEvaluation.selfDefinedObjectives || !finalEvaluation.selfDefinedObjectives.length">
                    <p class="placeholder margin" translate="defineObjectives.NO_OBJECTIVE"></p>
                  </ng-container>

                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="loader spinner-medium"></div>
                </ng-container>

              </div>
              <!-- Insight feedback -->
              <div class="page-header white-bg"
                *ngIf="!isLoadingFinalEvaluationData && finalEvaluation.insightFeedback && finalEvaluation.insightFeedback.matrixManager && finalEvaluation.insightFeedback.matrixManagerObservations && finalEvaluation.insightFeedback.matrixManagerObservations.strongPoints?.length">
                <div class="title-container">
                  <p class="title" translate="insight.NAME"></p>
                </div>

                <summary-card [user]="finalEvaluation.insightFeedback.matrixManager"
                  [observationData]="finalEvaluation.insightFeedback.matrixManagerObservations"
                  [isMatrixManagerFeedback]="true" [isEditDisabled]="true">
                </summary-card>

                <summary-card *ngIf="finalEvaluation.insightFeedback?.directManagerComment?.length"
                  [user]="finalEvaluation.insightFeedback.requiredByManager"
                  [matrixManager]="finalEvaluation.insightFeedback.matrixManager"
                  [directManagerComment]="finalEvaluation.insightFeedback.directManagerComment"
                  [isInsightManagerComment]="true" [isEditDisabled]="true">
                </summary-card>

              </div>

              <!-- Recap valutazione finale  + aree di miglioramento -->
              <div class="page-header white-bg" *ngIf="!isLeadershipTeam && !processIsBeforeH2023">
                <ng-container *ngIf="!isLoadingFinalEvaluationData">
                  <!-- Edit punti di forza e aree di sviluppo -->
                  <ng-container
                    *ngIf="(!isScheduleShared && !isPostCalibration && !isFinalStepCompleted) || editFinalEvaluationComment">
                    <!-- Titolo -->
                    <div class="title-container">
                      <p class="title" translate="finalEvaluation.STRENGTH_DEVELOPMENT_TITLE"></p>
                    </div>
                    <p class="description" *ngIf="personDetails" [innerHtml]=" ('finalEvaluation.STRENGTH_DEVELOPMENT_DESCR_1' | translate) + personDetails.forename +
                      ('finalEvaluation.STRENGTH_DEVELOPMENT_DESCR_2' | translate)">
                    </p>

                    <!-- Punti di forza -->
                    <input-container [numberCharsTextArea]="300" [isFromTextArea]="true"
                      [tooltipModalMessage]="strongPointTooltip"
                      [isValued]="finalEvaluation.ownObservations && finalEvaluation.ownObservations.strongPoints && finalEvaluation.ownObservations.strongPoints.trim().length"
                      [whiteBackground]="true" [isObbligatory]="false"
                      [title]="'finalEvaluation.STRONG_POINTS' | translate">
                      <alloy-text-area
                        [bindValue]="finalEvaluation.ownObservations && finalEvaluation.ownObservations.strongPoints"
                        [maxlength]="300" (onModelChanged)="onStrongPointChanged($event)"
                        [placeholder]="'finalEvaluation.STRONG_POINTS_PLACEHOLDER' | translate">
                      </alloy-text-area>
                    </input-container>

                    <!-- Aree di sviluppo -->
                    <input-container [numberCharsTextArea]="300" [isFromTextArea]="true"
                      [tooltipModalMessage]="developmentAreaTooltip"
                      [isValued]="finalEvaluation.ownObservations && finalEvaluation.ownObservations.developmentAreas && finalEvaluation.ownObservations.developmentAreas.trim().length"
                      [whiteBackground]="true" [isObbligatory]="false"
                      [title]="'finalEvaluation.DEVELOPMENT_AREAS' | translate">
                      <alloy-text-area
                        [bindValue]="finalEvaluation.ownObservations && finalEvaluation.ownObservations.developmentAreas"
                        [maxlength]="300" (onModelChanged)="onDevelopmentAreaChanged($event)"
                        [placeholder]="'finalEvaluation.DEVELOPMENT_AREAS_PLACEHOLDER' | translate">
                      </alloy-text-area>
                    </input-container>

                    <div class="observation-action">
                      <alloy-button [disabled]="!isFinalCommentChanged() || isFinalCommentEmpty()" type="success"
                        size="medium" [label]="'finalEvaluation.SAVE_COMMENT_EDIT' | translate"
                        (click)="savePartialEvaluationComment()">
                      </alloy-button>
                    </div>

                  </ng-container>

                  <!-- Readonly punti di forza e aree di sviluppo (+ vecchi note e commenti) -->
                  <ng-container *ngIf="isScheduleShared || isPostCalibration || isFinalStepCompleted">
                    <ng-container
                      *ngIf="!(loggedUser && finalEvaluation.comment && finalEvaluation.comment.length) && !(finalEvaluation.selfComment && finalEvaluation.selfComment.length)">
                      <ng-container *ngIf="!editFinalEvaluationComment">
                        <div class="title-container">
                          <p class="title" translate="finalEvaluation.STRENGTH_DEVELOPMENT_TITLE"></p>
                        </div>
                        <p class="description" *ngIf="personDetails" [innerHtml]=" ('finalEvaluation.STRENGTH_DEVELOPMENT_DESCR_1' | translate) + personDetails.forename +
                      ('finalEvaluation.STRENGTH_DEVELOPMENT_DESCR_2' | translate)">
                        </p>
                      </ng-container>
                      <!-- Osservazioni del PA -->
                      <ng-container *ngIf="finalEvaluation.ownObservations && !editFinalEvaluationComment">
                        <summary-card [user]="loggedUser" [observationData]="finalEvaluation.ownObservations"
                          [canEdit]="canEditPostCalibration()" [isEditDisabled]="!canEditPostCalibration()"
                          (onEdit)="onEditFinalEvaluationComment()">
                        </summary-card>

                      </ng-container>

                      <!-- Punti di forza e aree di sviluppo Self assessment SA -->
                      <ng-container
                        *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.colleagueObservations">
                        <summary-card [user]="personDetails" [observationData]="finalEvaluation.colleagueObservations">
                        </summary-card>
                      </ng-container>

                    </ng-container>
                    <!-- Commento PA e SA readonly -->
                    <ng-container
                      *ngIf="(loggedUser && !editFinalEvaluationComment && finalEvaluation.comment && finalEvaluation.comment.length) || (finalEvaluation.selfComment && finalEvaluation.selfComment.length)">
                      <div class="title-container">
                        <p class="title" translate="finalEvaluation.NOTES_COMMENTS"></p>
                      </div>
                      <!-- Commento valutazione finale manager -->
                      <ng-container
                        *ngIf="loggedUser && !editFinalEvaluationComment && finalEvaluation.comment && finalEvaluation.comment.length">
                        <evaluation-rating [user]="loggedUser"
                          [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + insertByManager"
                          [evaluationText]="finalEvaluation.comment"
                          [canEdit]="!isFinalStepCompleted && !isPostCalibration" [isEditDisabled]="isItemDisabled()"
                          (onEdit)="onEditFinalEvaluationComment()">
                        </evaluation-rating>
                      </ng-container>

                      <!-- Commento valutazione finale self assessment -->
                      <ng-container *ngIf="finalEvaluation.selfComment && finalEvaluation.selfComment.length">
                        <evaluation-rating [user]="personDetails"
                          [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + personDetails.forename"
                          [canEdit]="false" [evaluationText]="finalEvaluation.selfComment" [isSelf]="true">
                        </evaluation-rating>
                      </ng-container>
                    </ng-container>

                  </ng-container>

                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="loader spinner-medium"></div>
                </ng-container>

              </div>

              <div class="page-header white-bg" *ngIf="!isLeadershipTeam && processIsBeforeH2023">
                <div class="title-container">
                  <p class="title" translate="finalEvaluation.NOTES_COMMENTS"></p>
                </div>

                <ng-container *ngIf="!isLoadingFinalEvaluationData">
                  <ng-container
                    *ngIf="(!isScheduleShared && !isPostCalibration && !isFinalStepCompleted) || editFinalEvaluationComment">
                    <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                      [isValued]="finalEvaluation.comment && finalEvaluation.comment.trim().length"
                      [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}">
                      <alloy-text-area [bindValue]="finalEvaluation.comment" [maxlength]="300"
                        (onModelChanged)="onFinalEvaluationCommentChanged($event)"
                        placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                      </alloy-text-area>
                    </input-container>
                  </ng-container>


                  <ng-container *ngIf="isScheduleShared || isPostCalibration || isFinalStepCompleted">
                    <!-- Rating competenze tecniche people appraisal -->
                    <ng-container *ngIf="loggedUser && !editFinalEvaluationComment">
                      <evaluation-rating [user]="loggedUser"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + insertByManager"
                        [evaluationText]="finalEvaluation.comment"
                        [canEdit]="!isFinalStepCompleted && !isPostCalibration" [isEditDisabled]="isItemDisabled()"
                        (onEdit)="onEditFinalEvaluationComment()">
                      </evaluation-rating>
                    </ng-container>

                    <!-- Rating competenze tecniche self-assessment -->
                    <ng-container *ngIf="finalEvaluation.selfComment">
                      <evaluation-rating [user]="personDetails"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + personDetails.forename"
                        [canEdit]="false" [evaluationText]="finalEvaluation.selfComment" [isSelf]="true">
                      </evaluation-rating>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="loader spinner-medium"></div>
                </ng-container>

              </div>

              <!-- Condivisione finale -->
              <ng-container *ngIf="isPostCalibration && !isFinalStepCompleted">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title"> {{ 'finalEvaluation.FINAL_SHARE' | translate}}</p>
                  </div>
                  <p class="description" [innerHTML]="finalShareDescripton"></p>

                  <!-- Checkbox colloquio di feedback-->
                  <input-container [isWithoutInfoIcon]="true" [isValued]="feedbackCheck" [isObbligatory]="false"
                    [title]="'finalEvaluation.CONFIRM_FEEDBACK' | translate"
                    [disabled]="isLoadingFinalEvaluationData || !allStepCompleted()">
                    <alloy-check [label]="'finalEvaluation.CONFIRM_FEEDBACK_LABEL' | translate"
                      [checked]="feedbackCheck" (onCheck)="onFeedbackCheck()">
                    </alloy-check>
                  </input-container>

                </div>
              </ng-container>

              <div class="person-details-main-actions-banner">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn" *ngIf="!isScheduleShared && !isFinalStepCompleted">
                    <!-- Salva -->
                    <alloy-button *ngIf="!isSendEvalBtnVisibleAndEnabled() && !isConfirmShareBtnVisibleAndEnabled()"
                      [disabled]="isLoadingFinalEvaluationData" type="primary" size="medium"
                      label="{{'generic.SAVE' | translate}}" (onButtonClicked)="saveFinalEvaluationStep()">
                    </alloy-button>
                    <!-- Proponi valutazione -->
                    <alloy-button *ngIf="isSendEvalBtnVisibleAndEnabled()" type="primary" size="medium"
                      label="{{'finalEvaluation.SEND_EVAL' | translate}}"
                      [disabled]="isLoadingFinalEvaluationData || !allStepCompleted()"
                      (onButtonClicked)="openSendFinalEvaluation()">
                    </alloy-button>
                    <!-- Condividi scheda -->
                    <alloy-button *ngIf="isConfirmShareBtnVisibleAndEnabled()"
                      [disabled]="isLoadingFinalEvaluationData || isHistory" type="primary" size="medium"
                      label="{{'finalEvaluation.CONFIRM_SHARE' | translate}}" (onButtonClicked)="openFinalShareModal()">
                    </alloy-button>
                  </div>
                </div>
              </div>
            </ng-container>

          </child-content>

        </ng-container>
        <!-- Caricamento tab child content -->
        <ng-container *ngIf="isLoadingTab">
          <div class="loader spinner-big"></div>
        </ng-container>

      </ng-container>

      <!-- I miei appunti -->
      <ng-container *ngIf="isNoteSection">
        <child-content>
          <div class="page-header">
            <ng-container *ngIf="privateNotesList && privateNotesList.length">
              <div class="title-container">
                <p class="title" translate="headerDossier.MY_NOTES"></p>
                <!--<svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                (click)="openHelpModal('notes')">
              </svg-icon>-->
              </div>
              <p class="description" [innerHTML]="'headerDossier.MY_NOTES_DESCR' | translate"></p>
            </ng-container>

            <ng-container *ngIf="!isLoadingPrivateNotes">
              <div class="card-list-container">
                <!-- Lista appunti privati -->
                <ng-container *ngIf="loggedUser && privateNotesList && privateNotesList.length">
                  <card-notes *ngFor="let note of privateNotesList" [note]="note" [loggedUser]="loggedUser"
                    (onDelete)="openDeleteNoteModal($event)" (onEdit)="goToEditPrivateNote($event)"></card-notes>
                </ng-container>

                <!-- Placeholder se non ho appunti privati -->
                <ng-container *ngIf="!privateNotesList || !privateNotesList.length">
                  <no-data [title]="('privateNotes.TITLE' | translate)"
                    [description]="('privateNotes.PLACEHOLDER' | translate)" [hideIcon]="true">
                  </no-data>
                </ng-container>
              </div>
              <!-- Pulsanti -->
              <div class="button-container development">
                <alloy-button type="success" [label]="('privateNotes.INSERT_NOTE' | translate)"
                  (onButtonClicked)="createPrivateNote()"></alloy-button>
              </div>
            </ng-container>

            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingPrivateNotes">
              <div class="loader spinner-medium"></div>
            </ng-container>

          </div>
        </child-content>
      </ng-container>


    </div>
  </ng-container>
  <!-- Loader caricamento dati -->
  <ng-container *ngIf="isLoadingPersonDetails || isLoadingYearList">
    <div class="loader spinner-big"></div>
  </ng-container>
</div>

<!-- Modale di aiuto per le competenze -->
<modal id="competence-dictionary" (onClose)="closeCompetenceDictionaryModal()"
  (onConfirm)="closeCompetenceDictionaryModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <p class="modal-title black center" *ngIf="competenceForModal && !competenceForModal.isBehaviour">
    {{ (currentStep.id == 'techEvaluation' && yearsWithSpecialisticGoalsToEvaluate ? 'manageBehaviour.SPEC_TITLE' :
    'manageBehaviour.COMPETENCE_TITLE')
    |translate}}
  </p>
  <p class="modal-title black center" *ngIf="competenceForModal && competenceForModal.isBehaviour">
    {{ 'manageBehaviour.DICTIONARY_TITLE' | translate }}
  </p>
  <ng-container *ngIf="competenceForModal">
    <div class="competence-dictionary-container">
      <ng-container *ngIf="competenceForModal.parentBehavior">
        <div class="competence-description">
          <p class="title">{{ competenceForModal.parentBehavior.text}}</p>
          <p class="subtitle">{{ competenceForModal.title}}</p>
          <p class="descr">{{ competenceForModal.text }}</p>
        </div>
      </ng-container>
      <div class="competence-ratings">
        <ng-container *ngFor="let rating of competenceForModal.evaluationScale">
          <div class="rating-section">
            <star-rating [forceRating]="rating.scale"></star-rating>
            <p class="descr"> {{ rating.text }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</modal>

<!-- Conferma eliminazione obiettivo -->
<modal id="confirm-delete-saved-objective" (onClose)="closeDeleteSavedObjective()"
  (onConfirm)="closeDeleteSavedObjective(true)" confirmLabel="{{ 'generic.DELETE' | translate}}"
  modalId="confirm-delete-saved-objective">
  <modal-text-content [title]="'defineObjectives.modals.CONFIRM_DELETE_TITLE' | translate"
    [text]="'defineObjectives.modals.CONFIRM_DELETE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale che mostra le informazioni del Performer -->
<modal id="performerEvaluatorInfoModal" (onClose)="closePerformerModal()" [confirmLabel]="('generic.CLOSE' | translate)"
  (onConfirm)="closePerformerModal()">
  <performer-evaluator-info-modal-content [selectedInfoTabPerformerKey]="selectedInfoTabPerformerKey">
  </performer-evaluator-info-modal-content>
</modal>

<!-- Modale elimina appunto privato -->
<modal id="deleteNoteModal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteNoteModal(false)"
  (onClose)="closeDeleteNoteModal(false)" (onConfirm)="closeDeleteNoteModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pn014" [title]="'privateNotes.modals.DELETE_NOTE_TITLE' | translate"
    [text]="'privateNotes.modals.DELETE_NOTE_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione obiettivi -->
<modal id="save-objective-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveObjectivesModal(false,true)" (onClose)="closeSaveObjectivesModal(true, true)"
  (onConfirm)="closeSaveObjectivesModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe008" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione obiettivi -->
<modal id="save-defined-objective-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveDefinedObjectivesModal(false,true)" (onClose)="closeSaveDefinedObjectivesModal(true, true)"
  (onConfirm)="closeSaveDefinedObjectivesModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe002" [title]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Conferma eliminazione obiettivo -->
<modal id="confirm-delete-subordinate-saved-objective" (onClose)="closeSubordinateDeleteSavedObjective()"
  (onConfirm)="closeSubordinateDeleteSavedObjective(true)" confirmLabel="{{ 'generic.DELETE' | translate}}"
  modalId="confirm-delete-saved-objective">
  <modal-text-content [title]="'defineObjectives.modals.CONFIRM_DELETE_TITLE' | translate"
    [text]="'defineObjectives.modals.CONFIRM_DELETE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale conferma invio HR -->
<modal id="send-hr-schedule" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeSendFinalEvaluation()"
  (onClose)="closeSendFinalEvaluation()" (onConfirm)="closeSendFinalEvaluation(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="oe003" [title]="'finalEvaluation.SHARE_SCHEDULE' | translate"
    [text]="'finalEvaluation.SHARE_SCHEDULE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale conferma invio HR -->
<modal id="final-share-modal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeFinalShareModal()"
  (onClose)="closeFinalShareModal()" (onConfirm)="closeFinalShareModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="fs001" [title]="'finalEvaluation.CONFIRM_SHARE_TITLE' | translate"
    [subtitle]="'finalEvaluation.CONFIRM_SHARE_SUBTITLE' | translate" [text]="finalShareModalDescription">
  </modal-text-content>
</modal>

<!-- Modale blocco condivisione finale -->
<modal id="cannot-share-modal" [confirmLabel]="('generic.CLOSE' | translate)" (onConfirm)="closeCannotShareModal()"
  (onClose)="closeCannotShareModal()">
  <modal-text-content modalId="fs002" [title]="'finalEvaluation.CANNOT_SHARE_TITLE' | translate"
    [text]="'finalEvaluation.CANNOT_SHARE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione comportamenti -->
<modal id="save-behaviours-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveBehavioursModal(false,true)" (onClose)="closeSaveBehavioursModal(true, true)"
  (onConfirm)="closeSaveBehavioursModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione tech skill -->
<modal id="save-tech-skills-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveTechSkillsModal(false,true)" (onClose)="closeSaveTechSkillsModal(true, true)"
  (onConfirm)="closeSaveTechSkillsModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe004" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva riepilogo -->
<modal id="save-final-evaluation-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveFinalEvaluationModal(false,true)" (onClose)="closeSaveFinalEvaluationModal(true, true)"
  (onConfirm)="closeSaveFinalEvaluationModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe005" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>



<!-- Modifica obiettivo -->
<modal id="edit-objective-modal" (onClose)="closeEditObjectiveModal()" [confirmLabel]="'generic.EDIT' | translate"
  (onConfirm)="closeEditObjectiveModal(true)" (onCancel)="closeEditObjectiveModal()"
  [cancelLabel]="'generic.CANCEL' | translate">
  <div class="custom-modal">
    <!-- Titolo, subtitolo-->
    <modal-text-content modalId="oed001" [title]="'finalEvaluation.modals.EDIT_SUB' | translate"
      [subtitle]="'finalEvaluation.modals.EDIT_SUB' | translate" text=''>
    </modal-text-content>
    <!-- Area di testo-->
    <ng-container *ngIf="editObjectiveData">
      <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
        [isValued]="editObjectiveData.title && editObjectiveData.title.trim().length" [isObbligatory]="false">
        <alloy-text-area [bindValue]="editObjectiveData.title" [maxlength]="300"
          (onModelChanged)="onObjectiveEdit($event)" placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
        </alloy-text-area>
      </input-container>
    </ng-container>
  </div>
</modal>

<!-- Elimina obiettivo -->
<modal id="delete-objective-modal" (onClose)="closeDeleteObjectiveModal()"
  [confirmLabel]="'generic.CONFIRM' | translate" (onConfirm)="closeDeleteObjectiveModal(true)"
  (onCancel)="closeDeleteObjectiveModal()" [cancelLabel]="'generic.CANCEL' | translate">
  <modal-text-content modalId="oe006" [title]="'finalEvaluation.modals.DELETE_TITLE' | translate"
    [subtitle]="'finalEvaluation.modals.DELETE_SUB' | translate" [text]="getDeleteModalText()">
  </modal-text-content>
</modal>

<!-- Approva obiettivo -->
<modal id="approve-objective-modal" (onClose)="closeApproveObjectiveModal()"
  [confirmLabel]="'generic.CONFIRM' | translate" (onConfirm)="closeApproveObjectiveModal(true)"
  (onCancel)="closeApproveObjectiveModal()" [cancelLabel]="'generic.CANCEL' | translate">
  <modal-text-content modalId="oe007" [title]="'finalEvaluation.modals.APPROVE_TITLE' | translate"
    [subtitle]="'finalEvaluation.modals.APPROVE_SUB' | translate" [text]="getApproveModalText()">
  </modal-text-content>
</modal>


<!-- Invia richiesta insight feedback -->
<!-- Modifica obiettivo -->
<modal id="request-insight-feedback-modal" (onClose)="closeRequestInsightFeedbackModal()"
  [confirmLabel]="'insight.modals.SEND_REQUEST' | translate" (onConfirm)="closeRequestInsightFeedbackModal(true)"
  (onCancel)="closeRequestInsightFeedbackModal()" [cancelLabel]="'generic.CANCEL' | translate">
  <div class="insight-feedback-modal" *ngIf="selectedManagerForInsightFeedback && personDetails">

    <h2 translate="insight.REQUEST_FEEDBACK"></h2>

    <div class="divisor-line"></div>

    <p translate="insight.modals.REQUEST_DESCR"></p>
    <!-- Per chi-->
    <div class="person-label-container">
      <p translate="insight.modals.FOR_WHOM"></p>
      <div class="person-container">
        <avatar-img [user]="personDetails" size="extra-small"></avatar-img>
        <p>{{ personDetails.forename + ' ' + personDetails.surname }}</p>
      </div>

    </div>

    <!-- A chi-->
    <div class="person-label-container">
      <p translate="insight.modals.TO_WHOM"></p>
      <div class="person-container">
        <avatar-img [user]="selectedManagerForInsightFeedback" size="extra-small"></avatar-img>
        <p>{{selectedManagerForInsightFeedback?.title}}</p>
      </div>
    </div>
    <div class="motivation">
      <p class="m-title" translate="insight.modals.REQUEST_MOTIVATION_TITLE"></p>
      <p class="m-descr">
        <span translate="insight.modals.REQUEST_MOTIVATION_DESCR_1"></span>
        <b>{{ selectedManagerForInsightFeedback?.title }}</b>
        <span translate="insight.modals.REQUEST_MOTIVATION_DESCR_2"></span>
        <b>{{ personDetails.forename + ' ' + personDetails.surname }}</b>
        <span translate="insight.modals.REQUEST_MOTIVATION_DESCR_3"></span>
      </p>
    </div>
  </div>
</modal>

<modal id="additional-comment-helper" (onClose)="closeAdditionalCommentHelper()"
  [confirmLabel]="'generic.CLOSE' | translate" (onConfirm)="closeAdditionalCommentHelper()">
  <modal-text-content modalId="acif001" [title]="'insight.modals.ADDITIONAL_COMMENT' | translate"
    [text]="'insight.modals.ADDITIONAL_COMMENT_DESCR' | translate">
  </modal-text-content>
</modal>