<div class="component-container">
  <ng-container *ngIf="!isAdminHeader && !isUpwardFeedbackHeader">
    <div class="info-container">
      <div class="data-container">
        <div class="user-container">
          <div [ngClass]="{'is-cpp-collaborators': isCppCollaborators}" class="user" *ngIf="user" id="userAvatar">
            <avatar-img [isCppCollaborators]="isCppCollaborators" size="big" [src]="user && user.avatar" [user]="user"
              [initials]="user | userInitials">
            </avatar-img>
            <div class="info">
              <div class="user-name">
                <p class="text-title" *ngIf="!isDashboard && !isCppCollaborators && !user.ruolo" translate="header.PAS">
                </p>
                <p class="text-title" *ngIf="isDashboard" translate="header.DASHBOARD"></p>
                <p class="text-description" *ngIf="!isCppCollaborators">{{user.surname}} {{user.forename}}</p>
                <p class="text-description margin-right10" *ngIf="isCppCollaborators"
                  translate="cppCollaborators.YOUR_TEAM"></p>
                <!-- Ruolo CPP -->
                <p class="text-role" *ngIf="!isCppCollaborators && user?.ruolo">{{user.ruolo}}</p>
              </div>
              <ng-container *ngIf="selectedYear">
                <ng-select class="header-dossier-select" [clearable]="false" [multiple]="false" [searchable]="false"
                  [disabled]="disabledSelect" [(ngModel)]="selectedYear" [items]="yearItems"
                  [ngClass]="{'disabledSelect': disabledSelect}"
                  placeholder="{{ ('goalSetting.SELECT_YEAR' | translate) }}"
                  (change)="disabledSelect ? '' :emitOnSelectedYearItem($event)">

                  <!-- Elementi selezionati -->
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <p>{{item.name || item.title}}</p>
                  </ng-template>

                  <!-- Template per l'inizio della ricerca -->
                  <ng-template ng-typetosearch-tmp>
                    <div class="ng-option disabled">{{ ('generic.START_SEARCH' | translate) }}</div>
                  </ng-template>
                  <!-- Template per i dati non trovati -->
                  <ng-template ng-notfound-tmp let-searchTerm="filter">
                    <div class="ng-option disabled">
                      <alloy-dropdown-item class="full-width" [item]='noDataObject'>
                      </alloy-dropdown-item>
                    </div>
                  </ng-template>
                  <!-- Template per i singoli item nelle options -->
                  <ng-template ng-option-tmp let-item="item" let-search="filter">
                    <alloy-dropdown-item class="full-width" [item]="item"></alloy-dropdown-item>
                  </ng-template>
                </ng-select>
              </ng-container>
            </div>
          </div>
          <!-- 
          <div class="logo">
            <div class="logo-container">
              <svg-icon class="small-logo" [applyClass]="true" src="./assets/img/icons/facile-it-logo.svg">
              </svg-icon>
            </div>
          </div>
          -->
        </div>
        <div class="button-container">
          <ng-container *ngIf="!hideAction">
            <div id="takeNoteBtn" class="button-create-interview">
              <alloy-button type="success" size="medium" label="{{'header.TAKE_NOTE' | translate}}"
                [disabled]="showLoader" (click)="emitOnOrganizeinterview()">
              </alloy-button>
            </div>
            <!-- <div class="button-create-interview" *ngIf="adminActionLabel">
              <alloy-button [disabled]="showLoader" type="success" size="medium"
                label="{{'dashboard.DOWNLOAD_REPORT' | translate}}" (click)="emitOnOrganizeinterview()">
              </alloy-button>
            </div> -->
          </ng-container>
        </div>
      </div>
      <!-- Da ripristinare se chiedono i colloqui -->
      <!--div class="notify-wrapper" *ngIf="interview">
        <div class="notify-container">
          <div class="interview-date">
            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="3" cy="3" r="3" fill="#FC5A60" />
            </svg>
            <p *ngIf="interview.isEditabled">{{('goalSetting.YOU_ORGANIZED_INTERVIEW_FOR' | translate) +
              (interview.startDate | date: 'dd/MM/YYYY')}}
            </p>
            <p *ngIf="!interview.isEditabled">{{('goalSetting.IT_ORGANIZED_INTERVIEW_FOR' | translate) +
              (interview.startDate | date: 'dd/MM/YYYY')}}
            </p>
          </div>
          <a *ngIf="interview.isEditabled" translate="generic.ANNULL_INTERVIEW"
            (click)="emitOnAnnullInterview(interview.feedbackDialogueId)"></a>
        </div>
      </div-->
    </div>
    <ng-container *ngIf="hasTabs">
      <div class="buttons-tab-container">
        <div class="tab-container">
          <div id="{{ item.id }}" class="button" (click)="menuButtonClicked(item)"
            *ngFor="let item of menuButtons; let i = index"
            [ngClass]="{'active': item.id == selectedMenuButton.id, 'disabled': item.isDisabled, 'circle-percentage': i == 0 && isPeopleAppraisalModule}">
            <p class="text-button">{{ item.name }}</p>
            <circle-percentage *ngIf="i == 0 && isPeopleAppraisalModule" [isFromHeaderDossier]="true"
              [title]="currentPercentage" [isButtonActive]="!(item.id == selectedMenuButton.id)"></circle-percentage>
            <!-- <badge-notifications *ngIf="item.badgeNumber" [badgeNumber]="item.badgeNumber" type="active">
            </badge-notifications> -->

            <svg-icon *ngIf="item.id == selectedMenuButton.id" fxShow.xs fxHide class="small-size arrow-down-mobile"
              [applyClass]="true" src="./assets/img/icons/chevron-down.svg"></svg-icon>
          </div>
          <div *ngIf="mobileMenuOpened" class="menu-container-mobile">
            <alloy-dropdown-item *ngFor="let item of mobileMenuItems" [item]="item" (click)="emitOnOptionClicked(item)">
            </alloy-dropdown-item>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <!-- admin -->
  <ng-container *ngIf="isAdminHeader">
    <div class="admin-container">
      <div class="left-section" *ngIf="adminBack">
        <div>
          <alloy-button iconLeft="assets/img/icons/arrow-left.svg" [label]="('generic.GO_BACK' | translate)"
            [disabled]="showLoader" (onButtonClicked)="redirectService.goBackBrowser()"
            type="primary black-link arrow-icon" size="medium">
          </alloy-button>
        </div>
      </div>
      <div class="center-section">
        <p class="admin-area" translate="generic.ADMIN_AREA"></p>
        <p class="title">{{adminPageTitle }}</p>
        <p class="subtitle">{{adminPageSubtitle}}</p>
      </div>
      <div class="right-section">
        <ng-container *ngIf="showLoader">
          <div class="loader spinner-small"></div>
        </ng-container>
        <ng-container *ngIf="adminActionLabel && adminActionLabel.length && !showLoader">
          <alloy-button [disabled]="adminActionDisabled || showLoader" [iconLeft]="adminActionIcon"
            [label]="adminActionLabel" (onButtonClicked)="emitOnAdminAction()" [type]="adminActionLabelType"
            size="medium">
          </alloy-button>
        </ng-container>
        <ng-container *ngIf="counter">
          <div class="counter-container">
            <p class="count">{{counter}}</p>
            <p class="label">{{counterLabel}}</p>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="hasTabs">
      <div class="buttons-tab-container process-setup">
        <div class="tab-container">
          <div id="{{ item.id }}" class="button" (click)="menuButtonClicked(item)" *ngFor="let item of menuButtons"
            [ngClass]="{'active': item.id == selectedMenuButton.id, 'disabled': item.isDisabled}">
            <p class="text-button">{{item.name}}</p>
            <badge-notifications *ngIf="item.badgeNumber" [badgeNumber]="item.badgeNumber" type="active">
            </badge-notifications>
            <svg-icon *ngIf="item.id == selectedMenuButton.id" fxShow.xs fxHide class="small-size arrow-down-mobile"
              [applyClass]="true" src="./assets/img/icons/chevron-down.svg"></svg-icon>
          </div>
          <div *ngIf="mobileMenuOpened" class="menu-container-mobile">
            <alloy-dropdown-item *ngFor="let item of mobileMenuItems" [item]="item" (click)="emitOnOptionClicked(item)">
            </alloy-dropdown-item>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isUpwardFeedbackHeader">
    <div class="info-container upward-feedback">
      <div class="data-container">
        <div class="user-container">
          <div class="user" *ngIf="user" id="userAvatar">
            <avatar-img size="big" [src]="user && user.avatar" [user]="user" [initials]="user | userInitials">
            </avatar-img>
            <div class="info">
              <div class="user-name">
                <p class="text-title" translate="upwardFeedback.peopleAppraisal.HEADER_LIST"></p>
                <p class="upward-feedback-name">{{user.surname}} {{user.forename}}</p>
              </div>
              <ng-container *ngIf="selectedRound">
                <ng-select class="header-dossier-select" [clearable]="false" [multiple]="false" [searchable]="false"
                  [disabled]="disabledSelect" [(ngModel)]="selectedRound" [items]="roundList"
                  [ngClass]="{'disabledSelect': disabledSelect}"
                  placeholder="{{ ('goalSetting.SELECT_YEAR' | translate) }}"
                  (change)="disabledSelect ? '' :emitOnSelectedRoundItem($event)">

                  <!-- Elementi selezionati -->
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <p>{{item.name }}</p>
                  </ng-template>

                  <!-- Template per l'inizio della ricerca -->
                  <ng-template ng-typetosearch-tmp>
                    <div class="ng-option disabled">{{ ('generic.START_SEARCH' | translate) }}</div>
                  </ng-template>
                  <!-- Template per i dati non trovati -->
                  <ng-template ng-notfound-tmp let-searchTerm="filter">
                    <div class="ng-option disabled">
                      <alloy-dropdown-item class="full-width" [item]='noDataObject'>
                      </alloy-dropdown-item>
                    </div>
                  </ng-template>
                  <!-- Template per i singoli item nelle options -->
                  <ng-template ng-option-tmp let-item="item" let-search="filter">
                    <alloy-dropdown-item class="full-width" [item]="item"></alloy-dropdown-item>
                  </ng-template>
                </ng-select>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>