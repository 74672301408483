import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';

@Component({
  selector: 'team-sidebar',
  templateUrl: './team-sidebar.component.html',
  styleUrls: ['./team-sidebar.component.scss'],
})
export class TeamSidebarComponent implements OnInit {
  @Input() users?: any[];
  @Input() activeUserId: string | null = null;
  @Input() isLoadingUsers: boolean = false;
  @Input() hasImpersonateBanner: boolean = false;
  @Input() isOpenedSecondLevel: boolean = false;
  @Input() structureUsers?: any[];
  @Input() isCppSubordinates: boolean = false;
  @Input() isInsightFeedback: boolean = false;
  @Output() userClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() secondLevelOpen: EventEmitter<any> = new EventEmitter<any>();

  searchedText: string | null = null;
  searchResults: any[] = [];
  searchStructureResults: any[] = [];
  constructor(
    public cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  onUserSearch(text: any) {
    if (this.users && this.searchedText) {
      let tempSearch = this.searchedText.toLowerCase();
      this.searchResults = [];
      for (var i = 0; i < this.users.length; i++) {
        if (this.isInsightFeedback) {
          let user = this.users[i].subordinateUser;
          if (user.forename && user.surname &&
            (user.forename.toLowerCase().indexOf(tempSearch) != -1 || user.surname.toLowerCase().indexOf(tempSearch) != -1)) {
            this.searchResults.push(user);
          }
        } else {
          if (this.users[i].forename && this.users[i].surname &&
            (this.users[i].forename.toLowerCase().indexOf(tempSearch) != -1 || this.users[i].surname.toLowerCase().indexOf(tempSearch) != -1)) {
            this.searchResults.push(this.users[i]);
          }
        }
      }
    } else {
      this.searchResults = [];
    }

    if (this.structureUsers && this.searchedText) {
      let tempSearch = this.searchedText.toLowerCase();
      this.searchStructureResults = [];
      for (var i = 0; i < this.structureUsers.length; i++) {
        if (this.structureUsers[i].forename && this.structureUsers[i].surname &&
          (this.structureUsers[i].forename.toLowerCase().indexOf(tempSearch) != -1 || this.structureUsers[i].surname.toLowerCase().indexOf(tempSearch) != -1)) {
          this.searchStructureResults.push(this.structureUsers[i]);
        }
      }
    } else {
      this.searchStructureResults = [];
    }
  }

  onUserClicked(user: any) {
    if (this.isCppSubordinates) {
      if (user?.surveyCompleted) {
        this.userClicked.emit(user);
      }
    } else {
      this.userClicked.emit(user);
    }
    this.cdr.detectChanges();
  }

  onSecondLevelOpen() {
    this.secondLevelOpen.emit(!this.isOpenedSecondLevel);
  }

  userId(index: number, el: any) {
    return el.userId;
  }
}
